import React from "react"

import { Layout } from "../components/layout"

// cf: https://github.com/gatsbyjs/gatsby/issues/5329
const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => (
  browser && (
      <Layout style={{backgroundColor: 'red'}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
)

export default NotFoundPage
